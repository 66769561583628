import * as React from "react"
import Header from '../components/header';
import Footer from '../components/footer';
import { Link } from "gatsby";
import Banner from "../components/home_banner";
import ServicesList from "../components/services_list";
import ExploreTogether from "../components/explore_together";
import { SEO } from "../components/seo";

import about1 from '../images/about1.png'
import about2 from '../images/about2.jpeg'

// import "../styles/index.scss";

//https://www.radiustheme.com/demo/html/clenix/clenix/index2.html
const IndexPage = () => {
	return (
		<div className="container">
			<SEO />
			<Header />
			<Banner />
			<div className="section wework">
				<div className="innerwrap">
					<div className="wework-cols">
						<div className="col">
							<div className="imageholder">
								<div className="img1">
									<img src={about1} alt="Pooja Services Mangement Profile 1" />
								</div>
								<div className="img2">
									<img src={about2} alt="Pooja Services Mangement Profile 2" />
								</div>
							</div>

						</div>
						<div className="col">
							<div className="description">
								<p className="subhead">We Work For You</p>
								<h1>Facility Management & Manpower Services</h1>
								<p>We Pooja Services is one of the eminent service providers for security Guards, Bouncers, Housekeeping Staff, Manpower Services both Male and Female, and similar Services Across Maharashtra. Our Organisation was started with the aim to provide safe, reliable, dedicated & efficient means of protecting materials, machines, men, and property belonging to any individual/body organization.</p>
								<ul>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Experienced Team</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Keep the same cleaner for every visit</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> One-off, weekly or fortnightly visits</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Book, manage & pay online</li>
								</ul>
								<Link to="/about-us" className="btn">Read More <ion-icon name="chevron-forward"></ion-icon></Link>
							</div>

						</div>
					</div>

				</div>
			</div>
			<div className="section services">
				<div className="innerwrap">

					<div className="section-title">Pooja Services</div>
					<div className="section-desc">We Pooja Services is one of the eminent service providers for security Guards, Bouncers, Housekeeping Staff, Manpower Services both Male and Female, and similar Services Across Maharashtra. </div>
					<ServicesList />

				</div>
			</div>
			<ExploreTogether />
			<div className="section howiworks">
				<div className="innerwrap">
					<h2 className="section-title">How It Works</h2>
					<p className="section-desc"> You will have a hassle-free service booking with our Experienced Team</p>
					<div className="steps">
						<div className="step">
							<span className="icon"> <ion-icon name="calendar-outline"></ion-icon> </span>
							<h3>Select The Service You Need</h3>
							<p>Get Hassle Free Service Delivery</p>
						</div>
						<div className="step">
							<span className="icon"> <ion-icon name="mail-unread-outline"></ion-icon> </span>
							<h3>Book your Service</h3>
							<p>Select date and timeslot and complete service booking</p>
						</div>
						<div className="step">
							<span className="icon"> <ion-icon name="medal-outline"></ion-icon> </span>
							<h3>Get Hassle Free Service Delivery</h3>
							<p>Pooja Service Partner arrives at your doorstep to clean your house</p>
						</div>
					</div>
					
				</div>
			</div>
			<div className="section specialists"></div>

			<Footer />
		</div>
	)
}


export default IndexPage
