import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import banner1 from '../static/pooja_services_banner_1.jpg';
import banner2 from '../static/pooja_services_banner_2.jpg';



const Banner = () => {

    var number = Math.floor((Math.random() * 100) + 1);
    const images = [
        { url: number % 2 === 0 ? banner1 : banner2 }
    ];

    return(
        <div className="home_banners">
            <SimpleImageSlider
            width="100%"
            height={700}
            images={images}
            showBullets={false}
            showNavs={false}
            bgColor="#fff"
        />
        </div>
    )
}

export default Banner;